<style>
    .pa-filter-icon {
        display: inline-flex;
    }

    .pa-filter-box .pa-flyout-box-content {
        padding: 0;
    }

    .filter-label {
        padding-left: 8px;
        line-height: 18px;
    }
</style>

<template>
    <div class="pa-filter-box" :id="id">
        <p-flyout
            ref="flyout"
            direction="bottom"
            :width="216"
            :lock-scroll="lockScroll"
            :positioning="positioning"
            :popper-direction="popperDirection"
            offset="-5%p, 5px"
        >
            <p-button
                color="blue"
                variant="ghost"
                slot="trigger"
                :disabled="disabled"
                class="pa-filter-button"
                :class="{ 'isActive': isFilterActive }"
            >
                <span class="pa-filter-icon">
                    <p-icon
                        v-if="filterIcon"
                        :icon="filterIcon"
                        size="lg"
                        block
                        color="blue"
                    >
                    </p-icon>
                    <span v-if="filterLabel" class="filter-label">
                        {{ filterLabel }}
                    </span>
                </span>
            </p-button>
            <div slot="content">
                <ul v-if="options.length" class="pa-filter-list">
                    <li v-for="option in options">
                        <label class="pa-option">
                            <input @click="select(option.value)"
                                type="radio"
                                class="pa-option-input"
                                :value="option.value"
                                v-model="model"/>
                            <span class="pa-option-icon pa-option-icon_radio"></span>
                            <span class="pa-option-txt pa-txt_truncate">{{ option.label }}</span>
                        </label>
                    </li>
                </ul>
                <div v-if="optgroups.length" class="pa-filter-list_groups">
                    <div v-for="group in optgroups">
                        <div class="group-header">{{ group.label }}</div>
                        <ul class="pa-filter-list">
                            <li v-for="option in group.options">
                                <label class="pa-option">
                                    <input @click="select(option.value)"
                                        type="radio"
                                        class="pa-option-input"
                                        :value="option.value"
                                        v-model="model"/>
                                    <span class="pa-option-icon pa-option-icon_radio"></span>
                                    <span class="pa-option-txt">{{ option.label }}</span>
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </p-flyout>
    </div>
</template>

<script>
import Vue from 'vue';
import isComponentInModal from '../utils/isComponentInModal';

const Filter = Vue.extend({
    data() {
        return {};
    },

    props: {
        src: {
            type: String,
            default: 'incident'
        },
        options: {
            type: Array,
            'default': () => [],
        },

        optgroups: {
            type: Array,
            'default': () => [],
        },

        model: [String, Number],

        id: String,

        onChangeCallback: {
            type: Function,
            'default': () => {},
        },

        disabled: {
            type: Boolean,
            'default': false,
        },

        lockScroll: {
            type: Boolean,
            default: true,
        },

        positioning: {
            type: String,
            default: 'fixed',
        },

        popperDirection: String,

        filterIcon: {
            type: String,
            default: "filter-outline"
        },

        filterLabel: String,

        isButton: Boolean,

        filter: String,

        default: Boolean,
    },

    methods: {
        select(value) {
            if (this.src == 'incident') {
                this.eventHub.$emit('incident-overview:update_data', value, this.filter);
                this.onChangeCallback();
            }
            else {
                this.eventHub.$emit('fabric-connection:update_data', value, this.filter);
            }            
        },

    },

    computed: {
        isFilterActive() {
            if (this.default && this.options.length) {

                return this.model !== this.options[0].value
            }
            return this.model
        },
        isEmpty: function() {
            return _.isEmpty(this.options);
        },
    },
});

export default Filter;
</script>

//
// Parses URL query parameters
// Returns an object of the parsed key/val
// `search` = window.location.search
// If you pass window.location.href, it will work but
// also include the url hash if there is one.
//
export default function getUrlParams(search = '') {
    const params = {};

    if (search === "" || !Boolean(search)) {
        return params;
    }

    const hashes = search.slice(search.indexOf("?") + 1).split("&");

    hashes.forEach(hash => {
        hash = hash.split("=");

        const key = hash[0];
        const val = hash[1];

        if (key === "__proto__" || key === "constructor" || key === "prototype") { // Ignore unsafe keys
            return; 
        } else {
            params[key] = decodeURIComponent(val);
        }
    });

    return params;
}
